import React, { ReactNode } from 'react';
import { useLocation } from '@reach/router';
import Header from 'components/Header';
import Cookies from 'components/Cookies';
import Footer from 'components/Footer';

import { useWindowSize } from 'hooks';

import waveVideo from 'videos/wave.mp4';
import waveImage from 'images/wave.png';

import {
  OuterWrapper,
  Wrapper,
  Main,
  WaveVideo,
  WaveImage,
  BackgroundImage,
} from './styled';

interface Props {
  children: ReactNode;
  animationAtBottom?: boolean;
}

const Layout = ({ children, animationAtBottom = true }: Props) => {
  const windowSize = useWindowSize();
  const { hash: hashUrl } = useLocation();
  const width = windowSize?.width ?? 0;

  const isMobile = width >= 768;
  const isDesktop = !isMobile;

  return (
    <>
      <BackgroundImage />
      <OuterWrapper>
        <Wrapper>
          <Header />
          <Main>{children}</Main>
          <Footer withAnimation={animationAtBottom} />
          {isMobile && (
            <WaveVideo muted loop autoPlay playsInline>
              <source src={waveVideo} type="video/mp4" />
            </WaveVideo>
          )}
          {isDesktop && (
            <WaveImage
              style={{
                top: hashUrl === '#hello' ? '420px' : '495px',
              }}
              src={waveImage}
              alt=""
            />
          )}
        </Wrapper>
      </OuterWrapper>
      <Cookies />
    </>
  );
};
export default Layout;
