import styled, { css } from 'styles/styled';

const NAV_ITEMS_HORIZONTAL_SPACING = '34px';

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: space-between;
  z-index: 2;

  @media ${({ theme }) => theme.devices.large} {
    align-items: center;
    justify-content: space-between;
    margin: ${({ theme }) => theme.dimensions.spacingBase10 * 4}px 0;
  }
`;

export const Wrapper = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  background-image: ${({ theme }) => theme.gradient.backgroundMobile};
  margin-left: auto;

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    flex-direction: row;
    position: relative;
    width: initial;
    height: initial;
    background-image: none;
    overflow: visible;
  }
`;

export const Image = styled.img`
  width: 148px;
  height: 31px;
  position: relative;
  z-index: 1;
  margin: 21px 0 31px 0;

  @media ${({ theme }) => theme.devices.large} {
    width: 239px;
    height: 50px;
    margin: 0 ${({ theme }) => theme.dimensions.spacingBase10 * 5}px 0 0;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-left: 0;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    height: 100%;
  }
`;

export const ListItem = styled.li`
  position: relative;
  min-height: 50px;

  @media ${({ theme }) => theme.devices.large} {
    min-height: auto;
    margin-right: ${NAV_ITEMS_HORIZONTAL_SPACING};

    :first-of-type {
      margin-left: ${NAV_ITEMS_HORIZONTAL_SPACING};
    }
  }
`;

const menuElementStyles = css`
  color: ${({ theme }) => theme.palette.light};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
`;

export const Span = styled.span`
  white-space: nowrap;
  &:before {
    display: block;
    content: attr(title);
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const MenuElement = styled.div<{
  isActive?: boolean;
  isHidden?: boolean;
}>`
  ${menuElementStyles};
  font-size: ${({ theme }) => theme.fontSize.labelMedium};
  letter-spacing: 1.77px;
  line-height: normal;
  display: flex;

  :hover {
    opacity: 0.75;
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0.2;

      @media ${({ theme }) => theme.devices.large} {
        opacity: 1;
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      ${Span} {
        font-weight: 500;
      }

      &:after {
        content: '';
        position: absolute;
        top: ${({ theme }) => theme.dimensions.spacingBase10 * 2.3}px;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 2px solid ${({ theme }) => theme.palette.light};
      }

      @media ${({ theme }) => theme.devices.large} {
        ${Span} {
          font-weight: ${({ theme }) => theme.fontWeight.bold};
        }

        &:after {
          top: ${({ theme }) => theme.dimensions.spacingBase10 * 2.5}px;
        }
      }
    `}

  @media ${({ theme }) => theme.devices.large} {
    font-size: ${({ theme }) => theme.fontSize.base};
    letter-spacing: 1.67px;
    margin-bottom: 0;
  }
`;

export const Toggle = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px 0;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const Hamburger = styled.div<{ isOpen?: boolean }>`
  background-color: ${({ theme }) => theme.palette.light};
  width: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  height: 1px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'inherit')};
  top: -5px;

  ::before,
  ::after {
    width: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.light};
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -${({ theme }) => theme.dimensions.spacingBase10}px;
  }

  ::after {
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
    top: ${({ theme }) => theme.dimensions.spacingBase10}px;
  }

  @media ${({ theme }) => theme.devices.large} {
    top: 0;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.large} {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: block;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;
