import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

import { Animation } from './styled';

interface Props {
  speed?: number;
  className?: string;
}

const WaveAnimation = ({ className, speed = 0.03 }: Props) => {
  const mount = useRef<any>(null);

  useEffect(() => {
    const SEPARATION = 50;
    const AMOUNTX = 55;
    const AMOUNTY = 250;

    let camera: any;
    let scene: any;
    let renderer: any;

    let particles: any;
    let count = 0;

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight / 3);
    };

    const init = () => {
      camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        1,
        10000,
      );

      // set position
      camera.position.y = 1000;
      camera.position.z = 0;
      camera.position.x = -2250;

      scene = new THREE.Scene();

      const numParticles = AMOUNTX * AMOUNTY;

      const positions = new Float32Array(numParticles * 3);
      const scales = new Float32Array(numParticles);

      let i = 0;
      let j = 0;

      for (let ix = 0; ix < AMOUNTX; ix += 1) {
        for (let iy = 0; iy < AMOUNTY; iy += 1) {
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2; // x
          positions[i + 1] = 0; // y
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2; // z

          scales[j] = 1;

          i += 3;
          j += 1;
        }
      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3),
      );
      geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));

      const material = new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(0x00c9de) },
        },
        vertexShader:
          document.getElementById('vertexshader')?.textContent || '',
        fragmentShader:
          document.getElementById('fragmentshader')?.textContent || '',
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: false,
      });

      particles = new THREE.Points(geometry, material);
      particles.position.set(0, 100, 0); // set position in canvas
      scene.add(particles);

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight / 3);
      mount.current.appendChild(renderer.domElement);

      window.addEventListener('resize', onWindowResize, false);
    };

    const render = () => {
      camera.lookAt(scene.position);

      const positions = particles.geometry.attributes.position.array;
      const scales = particles.geometry.attributes.scale.array;

      let i = 0;
      let j = 0;

      for (let ix = 0; ix < AMOUNTX; ix += 1) {
        for (let iy = 0; iy < AMOUNTY; iy += 1) {
          positions[i + 1] =
            Math.sin((ix + count) * 0.3) * 70 +
            Math.sin((iy + count) * 0.5) * 50;

          scales[j] =
            (Math.sin((ix + count) * 0.3) + 1) * 10 +
            (Math.sin((iy + count) * 0.1) + 1) * 10;

          i += 3;
          j += 1;
        }
      }

      particles.geometry.attributes.position.needsUpdate = true;
      particles.geometry.attributes.scale.needsUpdate = true;

      renderer.render(scene, camera);

      count += speed; // speed change
    };

    const animate = () => {
      requestAnimationFrame(animate);

      render();
    };

    init();
    animate();
  }, [mount, speed]);

  return (
    <Animation
      className={className}
      ref={(el) => {
        mount.current = el;
      }}
    />
  );
};

export default WaveAnimation;
