import styled from 'styles/styled';

import { Button } from 'components/ui/Button';
import { StyledLink } from 'components/JoinUs/styled';

export const Wrapper = styled.div<{ show: boolean }>`
  background-color: #f8f8f8;
  bottom: 0;
  left: 0;
  position: fixed;
  padding: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  z-index: 5;
  right: 0;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.1);
  transform: ${({ show }) => `translateY(${show ? '0%' : '100%'})`};
  transition: ${({ theme }) => theme.ui.transition('transform')};

  @media ${({ theme }) => theme.devices.medium} {
    padding: ${({ theme }) => theme.dimensions.spacingBase10 * 5}px;
  }
`;

export const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  margin: 0 auto;

  @media ${({ theme }) => theme.devices.medium} {
    align-items: center;
    display: flex;
  }
`;

export const CopyWrapper = styled.div`
  @media ${({ theme }) => theme.devices.medium} {
    margin-right: ${({ theme }) => theme.dimensions.spacingBase10 * 5}px;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-right: ${({ theme }) => theme.dimensions.spacingBase10 * 7}px;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.dark};
  letter-spacing: 0.23px;
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media ${({ theme }) => theme.devices.medium} {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.copy};
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10}px;
  }
`;

export const Copy = styled.p`
  color: rgba(0, 0, 0, 0.69);
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 18px;
  font-size: ${({ theme }) => theme.fontSize.baseMobile};
  margin-bottom: 0;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: normal;
  }

  a {
    transition: ${({ theme }) => theme.ui.transition('opacity')};
    font-weight: ${({ theme }) => theme.fontWeight.regular};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }

    @media ${({ theme }) => theme.devices.medium} {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }
`;

export const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.dark};
  color: ${({ theme }) => theme.palette.dark};
  font-size: ${({ theme }) => theme.fontSize.baseMobile};
  text-transform: none;
  margin: 20px auto 0 auto;
  padding: ${({ theme }) =>
    `${theme.dimensions.spacingBase10 * 1.1}px
    ${theme.dimensions.spacingBase10 * 2.1}px`};

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.base};
    padding: ${({ theme }) =>
      `${theme.dimensions.spacingBase10 * 1.5}px
    ${theme.dimensions.spacingBase10 * 2.5}px`};
    margin: 0 auto;
    flex-shrink: 0;
  }
`;
