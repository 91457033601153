import React from 'react';

const useToggle = (defaultValue = false) => {
  const [on, setIsOn] = React.useState(defaultValue);

  const setOn = React.useCallback(() => setIsOn(true), []);
  const setOff = React.useCallback(() => setIsOn(false), []);
  const toggle = React.useCallback(() => setIsOn((v) => !v), []);

  return { on, setOn, setOff, toggle };
};

export default useToggle;
