import React from 'react';

import { useToggle } from 'hooks';

export default function useScrollToAdditionalContent<
  T extends HTMLElement = HTMLDivElement
>(
  isAdditionalContentVisible: ReturnType<typeof useToggle>['on'],
  delay: number = 0,
) {
  const additionalContentRef = React.useRef<T>() as React.RefObject<T>;

  React.useLayoutEffect(() => {
    if (isAdditionalContentVisible) {
      // The timeout is for the cases where there's a content spacing rearrangement
      // after clicking the "read more" button, and `.scrollIntoView()` runs too fast,
      // which results in scrolling to the **previous** additional content position.
      setTimeout(() => {
        const y =
          additionalContentRef.current?.getBoundingClientRect().top ?? 0; // px

        const offset = 50; // px

        window.scrollTo({ top: y - offset, behavior: 'smooth' });
      }, delay);
    }
  }, [isAdditionalContentVisible]);

  return additionalContentRef;
}
