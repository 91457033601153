import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import Facebook from 'images/icons/Facebook';
import Linkedin from 'images/icons/Linkedin';
import Instagram from 'images/icons/Instagram';
import Mail from 'images/icons/Mail';
import { SOCIAL_MEDIA_LINKS } from 'constants/common';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Wrapper,
  ContentWrapper,
  Copyright,
  PrivacyAndTerms,
  Socials,
  SocialsWrapper,
  SocialMediaLink,
} from './styled';

interface Props {
  withAnimation?: boolean;
}
interface IconsMap {
  [key: string]: () => ReactElement;
}

const iconsMap: IconsMap = {
  facebook: () => <Facebook />,
  linkedIn: () => <Linkedin />,
  instagram: () => <Instagram />,
  mail: () => <Mail />,
};

const Footer = ({ withAnimation = false }: Props) => {
  const { allRightsReserved, privacyPolicy, followUs } = useCommonContent();

  return (
    <Wrapper withAnimation={withAnimation}>
      <ContentWrapper>
        <Copyright>
          <span>© {new Date().getFullYear()} CloudBest.&nbsp;</span>
          <span>{allRightsReserved}</span>
        </Copyright>
        <PrivacyAndTerms>
          {/* eslint-disable-next-line */}
          <Link to="/privacy-policy">{privacyPolicy}</Link>
          {/* eslint-disable-next-line */}
        </PrivacyAndTerms>
        <SocialsWrapper>
          <span>{followUs}</span>
          <Socials>
            {SOCIAL_MEDIA_LINKS.map(({ url, name, alt }) => (
              <SocialMediaLink
                href={url}
                key={alt}
                target="_blank"
                rel="noopener noreferrer">
                {iconsMap[name]()}
              </SocialMediaLink>
            ))}
          </Socials>
        </SocialsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;

function useCommonContent() {
  const {
    common: {
      pageContent: { followUs, privacyPolicy, allRightsReserved },
    },
  } = useStaticQuery(
    graphql`
      query {
        common: wpPage(title: { in: "Common" }) {
          pageContent {
            followUs
            privacyPolicy
            allRightsReserved
          }
        }
      }
    `,
  );

  return {
    followUs,
    privacyPolicy,
    allRightsReserved,
  };
}
