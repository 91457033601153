import React, { memo, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Copy,
  CopyWrapper,
  InnerWrapper,
  StyledButton,
  Title,
  Wrapper,
} from './styled';

const STORAGE_KEY = 'cookies';

const COOKIE_SETTINGS_LINK =
  'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en';

const Cookies = () => {
  const {
    thisWebsiteUsesCookies,
    allowAllCookies,
    policy,
  } = useCookiesContent();

  const accepted = useRef(
    typeof window !== 'undefined'
      ? window.localStorage.getItem(STORAGE_KEY)
      : false,
  ).current;
  const [show, setShow] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setShow(true));
  }, []);

  if (accepted) {
    return null;
  }

  const onAccept = () => {
    setShow(false);
    window.localStorage.setItem(STORAGE_KEY, '1');
  };

  return (
    <Wrapper show={show}>
      <InnerWrapper>
        <CopyWrapper>
          <Title>{thisWebsiteUsesCookies}</Title>
          <Copy
            dangerouslySetInnerHTML={{
              __html: policy
                .replace('{{PRIVACY_POLICY_LINK}}', '/privacy-policy')
                .replace('{{COOKIE_SETTINGS_LINK}}', COOKIE_SETTINGS_LINK),
            }}
          />
        </CopyWrapper>
        <StyledButton onClick={onAccept}>{allowAllCookies}</StyledButton>
      </InnerWrapper>
    </Wrapper>
  );
};

export default memo(Cookies);

function useCookiesContent() {
  const {
    ourDepartments: {
      pageContent: { thisWebsiteUsesCookies, allowAllCookies, policy },
    },
  } = useStaticQuery(
    graphql`
      query {
        ourDepartments: wpPage(title: { in: "Cookies" }) {
          pageContent {
            thisWebsiteUsesCookies
            allowAllCookies
            policy
          }
        }
      }
    `,
  );

  return { thisWebsiteUsesCookies, allowAllCookies, policy };
}
