import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Button } from './styled';

interface Props {
  withOpacity?: boolean;
}

const JoinUsButton = ({ withOpacity = false }: Props) => {
  const { joinUs } = useCommonContent();

  return (
    <Button to="/join-us" activeClassName="active" withOpacity={withOpacity}>
      {joinUs}
    </Button>
  );
};

export default JoinUsButton;

function useCommonContent() {
  const {
    common: {
      pageContent: { joinUs },
    },
  } = useStaticQuery(
    graphql`
      query {
        common: wpPage(title: { in: "Common" }) {
          pageContent {
            joinUs
          }
        }
      }
    `,
  );

  return { joinUs };
}
