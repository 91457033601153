import React from 'react';

const Mail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <defs>
      <filter id="cq2mi722fa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path id="5payu6ysjb" d="M0.001 0H30.001V30H0.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#cq2mi722fa)" transform="translate(-1103 -1010)">
        <g transform="translate(1103 1010)">
          <mask id="381ownzfuc" fill="#fff">
            <use xlinkHref="#5payu6ysjb" />
          </mask>
          <path
            fill="#000"
            d="M15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0zm7.031 8H7.97C6.882 8 6 8.914 6 10.042v9.916C6 21.085 6.882 22 7.969 22H22.03C23.12 22 24 21.086 24 19.958v-9.916C24 8.914 23.119 8 22.031 8zm.282 4.444v7.515c0 .162-.126.292-.282.292H7.97c-.155 0-.281-.13-.281-.292v-7.515l6.885 4.186c.263.16.59.16.855 0l6.885-4.186zM22.03 9.75c.156 0 .282.13.282.292v.373L15 14.86l-7.312-4.445v-.373c0-.161.126-.292.28-.292z"
            mask="url(#381ownzfuc)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Mail;
