import styled from 'styles/styled';

interface Props {
  borderColor?: string;
  color?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const Button = styled.button<Props>`
  background-color: transparent;
  border: ${({ borderColor, theme }) => theme.ui.border(borderColor)};
  border-radius: ${({ theme }) => theme.ui.borderRadius};
  color: ${({ theme, color }) => color ?? theme.palette.light};
  cursor: pointer;
  transition: ${({ theme }) => theme.ui.transition('all')};
  padding: 14px 45px;
  letter-spacing: 1.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: fit-content;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`;
