import styled from 'styles/styled';

// eslint-disable-next-line import/prefer-default-export
export const Animation = styled.div`
  position: absolute;
  width: 100vw;
  z-index: -1;
  bottom: 130px;
  filter: blur(1px);
  left: 0;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.large} {
    bottom: -20px;
  }

  @media (max-height: 730px) {
    bottom: -150px;
  }

  @media (min-height: 730px) and (max-height: 900px) {
    bottom: -80px;
  }
`;
