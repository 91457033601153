import React from 'react';
import styled, { css } from 'styles/styled';
import { Link } from 'gatsby';

export const Button = styled(({ withOpacity, ...props }) => (
  <Link {...props} />
))<{
  withOpacity?: boolean;
}>`
  align-items: center;
  background: transparent;
  border-radius: 25px;
  color: ${({ theme }) => theme.palette.light};
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  outline: none;
  position: relative;
  border-radius: 25px;
  width: fit-content;
  white-space: nowrap;
  line-height: normal;
  font-size: 12px;
  letter-spacing: 1.33px;
  padding: 11px 35px;

  @media ${({ theme }) => theme.devices.medium} {
    padding: 13px 28px;
    font-size: 15px;
    letter-spacing: 1.67px;
  }

  ${({ withOpacity }) =>
    withOpacity &&
    css`
      opacity: 0.2;

      @media ${({ theme }) => theme.devices.large} {
        opacity: 1;
      }
    `}

  .active {
    background: ${({ theme }) => theme.gradient.primary};

    div {
      background: ${({ theme }) => theme.palette.light};

      span {
        background-image: ${({ theme }) => theme.gradient.primary};
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    }
  }

  &:hover {
    background: ${({ theme }) => theme.gradient.primary};
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.gradient.primary};
    content: '';
    border-radius: 25px;
    clip-path: polygon(
      0 0,
      0 100%,
      25px 100%,
      25px calc(100% - 2px),
      22px 95.5%,
      16.75px 94.5%,
      11.25px 89%,
      8px 83%,
      5.5px 77%,
      4px 71%,
      3px 65%,
      2.25px 56.25%,
      2px 50%,
      2.25px 43.75%,
      3px 37.75%,
      4px 31.5%,
      5.5px 25%,
      8px 17.5%,
      11.25px 12%,
      16.75px 6.5%,
      22px 4.8%,
      25px 2px,
      calc(100% - 25px) 2px,
      calc(100% - 22px) 4.8%,
      calc(100% - 16.75px) 6.5%,
      calc(100% - 11.25px) 12%,
      calc(100% - 8px) 17.5%,
      calc(100% - 5.5px) 25%,
      calc(100% - 4px) 31.5%,
      calc(100% - 3px) 37.75%,
      calc(100% - 2.25px) 43.75%,
      calc(100% - 2px) 50%,
      calc(100% - 2.25px) 56.25%,
      calc(100% - 3px) 65%,
      calc(100% - 4px) 71%,
      calc(100% - 5.5px) 77%,
      calc(100% - 8px) 83%,
      calc(100% - 11.25px) 89%,
      calc(100% - 16.75px) 95%,
      calc(100% - 22px) 96%,
      calc(100% - 25px) calc(100% - 2px),
      25px calc(100% - 2px),
      25px 100%,
      100% 100%,
      100% 0
    );
  }
`;

export const Counter = styled.div`
  color: ${({ theme }) => theme.palette.light};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  height: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  background: ${({ theme }) => theme.gradient.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  transition: ${({ theme }) => theme.ui.transition('all')};

  ${Button}:hover & {
    background: ${({ theme }) => theme.palette.light};

    span {
      background-image: ${({ theme }) => theme.gradient.primary};
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }
`;
