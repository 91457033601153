import React from 'react';

const Linkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <defs>
      <filter id="o65ws9wfba">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path id="yy72vlw09b" d="M0.001 0H30.001V30H0.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#o65ws9wfba)" transform="translate(-1023 -1010)">
        <g transform="translate(1023 1010)">
          <mask id="w137uamenc" fill="#fff">
            <use xlinkHref="#yy72vlw09b" />
          </mask>
          <path
            fill="#000"
            fillRule="nonzero"
            d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm-3 21H9V11h3v10zm-1.527-11C9.578 10 9 9.329 9 8.498 9 7.651 9.596 7 10.51 7c.913 0 1.473.651 1.49 1.498 0 .83-.577 1.502-1.527 1.502zM23 21h-2.922v-5.419c0-1.261-.424-2.118-1.481-2.118-.807 0-1.287.58-1.499 1.138-.078.199-.097.48-.097.76v5.637h-2.924V14.34c0-1.22-.038-2.241-.077-3.12h2.539l.134 1.359h.058C17.116 11.94 18.06 11 19.636 11 21.558 11 23 12.34 23 15.219V21z"
            mask="url(#w137uamenc)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Linkedin;
