import styled from 'styles/styled';
import WaveAnimation from 'components/ui/WaveAnimation';

export const OuterWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding: 0 36.5px;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  min-height: 100vh;
`;

export const Main = styled.main`
  flex: 1;
  width: 100%;
  min-height: 520px;
`;

export const BackgroundImage = styled.div`
  background: url(/gradient.png) no-repeat bottom center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`;

export const StyledWaveAnimation = styled(WaveAnimation)`
  top: 450px;

  @media ${({ theme }) => theme.devices.medium} {
    top: 600px;
  }

  @media ${({ theme }) => theme.devices.large} {
    top: 680px;
  }
`;

export const WaveVideo = styled.video`
  max-width: 100%;
  position: absolute;
  top: 780px;
  z-index: -1;
  width: auto;

  @media (min-width: 900px) {
    top: 615px;
  }

  @media (min-width: 1920px) {
    width: 100%;
  }
`;

export const WaveImage = styled.img`
  max-width: 100%;
  position: absolute;
  transition: top ease 0.3s;
  z-index: -1;
  transform: scale(2);

  @media (min-width: 370px) {
    top: 475px;
  }

  @media (min-width: 550px) {
    top: 400px;
  }
`;
