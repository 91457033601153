export interface SocialMediaLinkType {
  url: string;
  name: string;
  alt: string;
}

export const SOCIAL_MEDIA_LINKS: SocialMediaLinkType[] = [
  {
    name: 'facebook',
    url: `https://www.facebook.com/cloudbest.official`,
    alt: `facebook`,
  },
  {
    name: 'linkedIn',
    url: `https://www.linkedin.com/company/cloudbest/`,
    alt: `linkedIn`,
  },
  {
    name: 'instagram',
    url: `https://www.instagram.com/cloudbest_official/?hl=en`,
    alt: `instagram`,
  },
  {
    name: 'mail',
    url: `mailto: Info@cloudbest.com`,
    alt: `mail`,
  },
];

export const HASHES = {
  hello: '#hello',
  aboutUs: '#about-us',
  ourExpertise: '#our-expertise',
  ourDepartments: '#our-departments',
};

export interface MenuItem {
  name: string;
  hash: string;
  children?: MenuItem[];
}

export const MENU_CONFIG = [
  {
    name: 'Hello!',
    hash: HASHES.hello,
  },
  {
    name: 'About us',
    hash: HASHES.aboutUs,
  },
  {
    name: 'Our Expertise',
    hash: HASHES.ourExpertise,
  },
  {
    name: 'Our Departments',
    hash: HASHES.ourDepartments,
  },
];
