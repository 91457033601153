import React, { useEffect } from 'react';
import { navigate, useLocation } from '@reach/router';
import { Link } from 'gatsby';

import Logo from 'images/logo.svg';
import { MENU_CONFIG, MenuItem, HASHES } from 'constants/common';

import JoinUsButton from 'components/JoinUsButton';

import { useToggle } from 'hooks';

import {
  StyledHeader,
  Image,
  MenuList,
  ListItem,
  MenuElement,
  Wrapper,
  Toggle,
  Hamburger,
  Span,
  DesktopWrapper,
  MobileWrapper,
} from './styled';

const Header = () => {
  const {
    on: isMenuOpen,
    toggle: toogleIsMenuOpen,
    setOff: closeMenu,
  } = useToggle();

  const { hash: hashUrl } = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isMenuOpen]);

  const handleElementClick = (hash: string) => navigate(`/${hash}`);

  return (
    <StyledHeader>
      <Link to={`/${HASHES.hello}`}>
        <Image src={Logo} alt="CloudBest Logo" />
      </Link>
      <Toggle onClick={toogleIsMenuOpen}>
        <Hamburger isOpen={isMenuOpen} />
      </Toggle>
      <Wrapper isOpen={isMenuOpen}>
        <MenuList>
          {MENU_CONFIG.map(({ hash, name }: MenuItem) => (
            <ListItem key={name}>
              <MenuElement
                isActive={hashUrl.includes(hash.substr(0, 6))}
                isHidden={false}
                onClick={() => {
                  handleElementClick(hash);
                  if (isMenuOpen) closeMenu();
                }}>
                <Span title={name}>{name}</Span>
              </MenuElement>
            </ListItem>
          ))}
        </MenuList>
        <MobileWrapper>
          <JoinUsButton />
        </MobileWrapper>
      </Wrapper>
      <DesktopWrapper>
        <JoinUsButton />
      </DesktopWrapper>
    </StyledHeader>
  );
};

export default Header;
