import React from 'react';

const Facebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30">
    <defs>
      <filter id="0xcevimwra">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path id="ktzvsxn0bb" d="M0.001 0H30.001V30H0.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#0xcevimwra)" transform="translate(-943 -1010)">
        <g transform="translate(943 1010)">
          <mask id="ghw0vww3dc" fill="#fff">
            <use xlinkHref="#ktzvsxn0bb" />
          </mask>
          <path
            fill="#000"
            fillRule="nonzero"
            d="M15 0C6.716 0 0 6.757 0 15.09 0 22.622 5.485 28.865 12.657 30V19.454h-3.81V15.09h3.81v-3.325c0-3.785 2.24-5.873 5.664-5.873 1.641 0 3.36.295 3.36.295v3.71h-1.896c-1.86 0-2.442 1.166-2.442 2.36v2.83H21.5l-.664 4.363h-3.493v10.546C24.515 28.867 30 22.623 30 15.091 30 6.757 23.284 0 15 0z"
            mask="url(#ghw0vww3dc)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Facebook;
