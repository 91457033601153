import styled from 'styles/styled';

export const Wrapper = styled.footer<{ withAnimation?: boolean }>`
  z-index: 2;
  color: ${({ theme }) => theme.palette.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: 0 auto ${({ theme }) => theme.dimensions.spacingBase10 * 4}px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  position: relative;
  margin-top: ${({ withAnimation }) => (withAnimation ? '40px' : 0)};

  span,
  a {
    color: ${({ theme }) => theme.palette.light};
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-top: ${({ withAnimation }) => (withAnimation ? '40px' : 0)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 4}px;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
  }
`;

export const Copyright = styled.div`
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10}px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.14;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

export const PrivacyAndTerms = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10}px;

  a {
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

export const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.14px;
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 0.5}px;
  }

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;

    span {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    }
  }
`;

export const Socials = styled.div`
  display: flex;
`;

export const SocialMediaLink = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  & + & {
    margin-left: ${({ theme }) => theme.dimensions.spacingBase10}px;
  }
`;
