import styled, { css } from 'styles/styled';

export const gradientTextStyles = css`
  background-image: ${({ theme }) => theme.gradient.primary};
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export const Heading = styled.h1`
  display: block;
  ${gradientTextStyles};
  text-align: center;
  margin: 80px 10px 0 10px;

  @media ${({ theme }) => theme.devices.medium} {
    margin: 53px auto 0 auto;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    text-align: left;
    white-space: nowrap;
  }
`;
